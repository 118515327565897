"use client";

import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

import { HeightWidthSpinner } from "@/components/HeightWidthSpinner";
import useLoggedIn from "@/hooks/useLoggedIn";
import {
  USE_OAUTH,
  ZAPIER_ORIGIN,
  isDevelopment,
  isPreview,
} from "@/utils/constants";

const LoginGuard = ({ children }: { children: React.ReactNode }) => {
  const { currentAccountId, isLoading } = useLoggedIn();
  // Only using this to protect against OAuth login loops now. If it works
  // well, we could use it to protect the standard login flow too.
  const [loginStatus, setLoginStatus] = useState<string>("unknown");
  const router = useRouter();

  useEffect(() => {
    // Something went wrong, so don't do anything.
    if (loginStatus === "error") {
      return;
    }
    if (isLoading) {
      return;
    }
    if (!currentAccountId) {
      if (USE_OAUTH) {
        // If we already tried to login, don't try again. Mark it as an error
        // and force a re-render.
        if (localStorage.getItem("loginStatus") === "pending") {
          localStorage.setItem("loginStatus", "error");
          setLoginStatus("error");
          return;
        }
        // Mark that we're trying to login and redirect.
        localStorage.setItem("loginStatus", "pending");
        signIn("zapier");
        return;
      }

      const logInURL = new URL("/app/login", ZAPIER_ORIGIN);

      // Using /app/extensions instead of pathname because otherwise
      // pathname is /app/login
      const path = window.location.pathname;
      const nextAfterLogin =
        isPreview || isDevelopment ? `${window.location.origin}${path}` : path;
      logInURL.searchParams.set("next", nextAfterLogin);
      router.push(logInURL.href);
    }
    if (USE_OAUTH) {
      // If we're here, we've successfully logged in. We could update the state,
      // but there's nothing new to re-render, so we won't bother.
      localStorage.setItem("loginStatus", "success");
    }
  }, [isLoading, currentAccountId, router, loginStatus]);

  if (isLoading) {
    return <HeightWidthSpinner />;
  }

  if (!currentAccountId) {
    if (loginStatus === "error") {
      return (
        <div>
          Login failed. Please try again. Do you have 3rd party cookies enabled?
        </div>
      );
    }
    return <div>Redirecting...</div>; //  TODO: Or maybe a spinner?
  }

  return <>{children}</>;
};

export default LoginGuard;
