"use client";

import { CurrentAccountIdProvider } from "@zapier/identity";

/*
Client wrapper for CurrentAccountIdProvider since
the RootLayout must be a server component.
*/
const CurrentAccountIdProviderWrapper = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <CurrentAccountIdProvider>{children}</CurrentAccountIdProvider>;
};

export default CurrentAccountIdProviderWrapper;
